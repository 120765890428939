.privacy {
    padding: 5%;
}
.wr {
    color: #fff;
}
h1, h2, h3, h4, p {
    font-family: Montserrat, sans-serif;
    letter-spacing: 0;
    text-align: left;
}
h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
}
h1 div {
    display: none;
}
h2 {
    margin-top: 30px !important;
    font-size: 38px;
    font-weight: 800;
    line-height: 57px;
}
h3 {
    font-size: 28px;
    font-weight: 800;
    line-height: 42px;
}
h4 {
    font-size: 18px;
    font-weight: 800;
    line-height: 27px;
}
p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.4px !important;
}
.wr > ol {
    list-style: none;
}
ol {
    padding: 0;
}
ol ol {
    padding-left: 100px;
}

ul > li {
    margin-bottom: 10px;
}

ol.paddingFree {
    padding: 0;
}

ol.defs > li {
    margin-left: 100px;
}

ol ol li {
    margin-bottom: 10px;
}
.privacy h4 {
    margin: 20px 0 !important;
}
.letter_type {
    list-style: lower-alpha;
    padding-left: 100px;
}
.roman_int_type {
    list-style: lower-roman;
}
.clean_list {
    list-style: none;
}
.int_type {
    list-style: auto;
}
.paragraph {
    margin-left: 100px;
}
@media (max-width: 1169px) {
    .letter_type {
        padding-left: 20px !important;
    }
    h1 {
        font-size: 24px !important;
        line-height: 36px !important;
        padding: 0 !important;
    }
    h1 div {
        display: unset;
    }
    h2 {
        font-size: 20px !important;
        line-height: 30px !important;
        padding: 0 !important;
    }
    h3 {
        font-size: 16px !important;
        line-height: 34px !important;
        padding: 0 !important;
    }
    h4 {
        font-size: 16px !important;
        line-height: 24px !important;
        padding: 0 !important;
    }
    p {
        font-size: 14px !important;
        line-height: 21px !important;
        letter-spacing: -0.4px !important;
    }
    .paragraph {
        margin-left: 77px;
    }
    .letter_type {
        padding-left: 77px;
    }
}
.legals_table {
    text-align: center;
    border-collapse: collapse;
    border: 1px solid white;
    min-width: 810px;
}
.legals_table_wrapper {
    max-width: 90vw;
    overflow-y: scroll;
}
.legals_table th {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    padding: 1%;
    border: 1px solid white;
    min-width: 40px;
}
.legals_table td {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    padding: 1%;
    border: 1px solid white;
}
.legals_table tr:first-child td {
    border-top: 0;
}
.legals_table tr td:first-child {
    border-left: 0;
}
.legals_table tr:last-child td {
    border-bottom: 0;
}
.legals_table tr td:last-child {
    border-right: 0;
}
.blueUpperCaseUnderlined a{
    text-decoration: underline !important;
    text-transform: uppercase !important;
    color: #3F8CF7 !important;
}
.blueUnderlined {
    text-decoration: underline !important;
    color: #3F8CF7 !important;
}
.greyUnderlined {
    text-decoration: underline !important;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    color: #fff !important;
    opacity: 0.8;
}
.justUnderlined {
    text-decoration: underline !important;
    white-space: nowrap;
}
strong {
    font-weight: 700 !important;
}
.line {
    display: flex;
    flex-direction: row;
}
.line p {
    margin: 0;
    padding: 0;
}
i {
    font-style: italic;
}