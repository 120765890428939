@import "views/Static/policy.css";

#modal {
  position: absolute;
  width: 100vw;
  z-index: 10000;
}

mark {
  background-color: transparent !important;
  color: #EB5560 !important;
}

a {
  text-decoration: none;
}